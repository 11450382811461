/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_UserIdentityWarning {
    /* 42px is the padding-left of .mx_MessageComposer_wrapper in res/css/views/rooms/_MessageComposer.pcss */
    margin-left: calc(-42px + var(--RoomView_MessageList-padding));

    .mx_UserIdentityWarning_row {
        display: flex;
        align-items: center;

        .mx_BaseAvatar {
            margin-left: var(--cpd-space-2x);
        }
        .mx_UserIdentityWarning_main {
            margin-left: var(--cpd-space-6x);
            flex-grow: 1;
        }
    }
}

.mx_MessageComposer.mx_MessageComposer--compact > .mx_UserIdentityWarning {
    margin-left: calc(-25px + var(--RoomView_MessageList-padding));
}

/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_RoomHeader {
    height: 64px;
    box-sizing: border-box;
    padding: 0 var(--cpd-space-3x) 0 calc(var(--cpd-space-3x) + var(--cpd-space-1-5x));
    border-bottom: 1px solid $separator;
    background-color: $background;
    transition: all 0.2s ease;
}

.mx_RoomHeader:hover {
    background-color: $header-panel-bg-hover;
}

.mx_RoomHeader_infoWrapper {
    /* unset button styles */
    background: unset;
    border: unset;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: var(--cpd-space-3x);
    text-align: left;
    height: 100%;
    padding: 0;
}

.mx_RoomHeader_info {
    /* We want the spacing to be 64px, 13x = 52px but we have a flex gap of
       12px set on the flex container, which sums up to 64 */
    padding-right: var(--cpd-space-13x);
}

.mx_RoomHeader_truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
}

.mx_RoomHeader_heading {
    display: flex;
    gap: var(--cpd-space-1x);
    align-items: center;
}

.mx_RoomHeader_icon {
    flex-shrink: 0;
}

.mx_RoomHeader .mx_FacePile {
    color: $secondary-content;
    background: $background;
    display: flex;
    align-items: center;
    gap: var(--cpd-space-2x);
    border-radius: 9999px;
    padding: var(--cpd-space-1-5x);
    cursor: pointer;
    user-select: none;

    /* RoomAvatar doesn't pass classes down to avatar
    So set style here
    using div because compound classes are not stable */
    > div {
        flex-shrink: 0;
    }

    &:hover {
        color: $primary-content;
        background: var(--cpd-color-bg-subtle-primary);
    }
}

.mx_RoomHeader .mx_BaseAvatar {
    flex-shrink: 0;
}

.mx_RoomHeader_videoCallOption {
    /* Workaround for https://github.com/element-hq/compound/issues/331 */
    min-width: 240px;
}
